/**
 * Функция для получения версии ios
 * @returns массив с тремя числами - мажорной, минорной и фиксовыми версиями, либо пустой массив.
 * Взято из:
 *  @see https://stackoverflow.com/questions/8348139/detect-ios-version-less-than-5-with-javascript
 */
export const getIOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.userAgent)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    const v = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return v
      ? [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)]
      : [];
  }

  return [];
};
