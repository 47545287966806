import { RawDraftContentBlock } from 'draft-js';
import React from 'react';

import generateChpu from 'utils/generateChpu';

enum BlockType {
  atomic = 'atomic',
  codeBlock = 'code-block',
  adv = 'adv',
  author = 'author',
  h2 = 'header-two',
}

/**
 * Мидлвара преобразования блоков драфта в html
 *
 * все блоки для которых не указано кастомное преобразование,
 * обрабатываются по дефолту пакета draft-convert
 *
 * @param block - draft block
 */
export const blockToHtmlMiddleware = (
  block: RawDraftContentBlock,
) => {
  const {
    type,
    data,
    text,
  } = block;

  switch (type) {
    case BlockType.atomic: {
      if (data?.type === 'image') {
        return <img src={data.src} alt="main_image" width={data.width} />;
      }

      return null;
    }

    case BlockType.codeBlock: {
      return (
        <div data-block-type="code">
          {text}
        </div>
      );
    }

    case BlockType.adv: {
      return (
        <div
          data-block-type="adv"
          data-block-adv-name={text}
        />
      );
    }

    case BlockType.author: {
      if (text) {
        return (
          <span className="draftAuthor">{text}</span>
        );
      }

      return <span className="empty" />;
    }

    case BlockType.h2: {
      return (
        <h2 {...(data?.withAnchors && { id: generateChpu(text) })}>{text}</h2>
      );
    }

    default: return null;
  }
};
